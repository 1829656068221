import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import HomeComponent from '../../components/home/HomeComponent';

function HomeTemplate({ cmsData }) {

    const homeData = cmsData?.home?.edges[0]?.node?.frontmatter ?? {}

    return (
        <div>
            <HomeComponent cmsdata={homeData}/>
        </div>
    )
}

export default function Home() {
    return (
      <StaticQuery
        query={graphql`
          query {
            home: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "home"}}}) {
              edges {
                node {
                  frontmatter {
                    title
                    seo {
                      title
                      descr
                    }
                    hero {
                        heroBackground {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        brand {
                            childImageSharp {
                                fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        logo {
                            childImageSharp {
                              fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                        }
                        textRotator {
                            text
                        }
                        heroText
                        heroButtonText
                        heroButtonPath
                    }

                    videoSection {
                        videoUrl
                        title
                        text
                        buttonText
                        buttonPath
                    }

                    sellingPoints {
                        title
                        arrow {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        points {
                            title
                            text
                        }
                        robotHead {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                    }
                    customerTitle
                    squares {
                        title1
                        text1
                        bg1 {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        buttonText1
                        buttonPath1
                        title2
                        text2
                        bg2 {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        buttonText2
                        buttonPath2
                        image1 {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        image2 {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                    }

                    endSection {
                        title
                        icon {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        buttonText
                        buttonPath
                    }
                  }
                }
              }
            },
          }
        `}
        render={(data) => <HomeTemplate cmsData={data} />}
      />
    );
  }
  